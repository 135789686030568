<template>
  <div class="report-wrapper">
    <Loader v-if="loading" />
    <CopyIframeDialog
      :iframe="iframe"
      :dialog="iframeDialog"
      @changeIframeDialog="handleChangeIframeDialog"
    />
    <SnackBar
      v-if="snackbarData.show"
      :text="snackbarData.text"
      :isShowSnackBar="snackbarData.show"
      :timeout="snackbarData.timeout"
      :setSnackBarData="setSnackBarData"
    />
    <div class="form">
      <v-container>
        <h1>{{ singleReport.title }}</h1>
      </v-container>
      <ReportForm
        :type="types[this.$router.currentRoute.params.producttype]"
        :load-pdf="loadPdf"
      />
    </div>
    <ModalConfirm
      :textActionButtons="textActionButtons"
      @change="onDialogChanged"
      :dialog="dialog"
      :confirm-action="update"
      text="Geben Sie Ihrem Bericht einen Namen"
      title="Ansicht speichern"
    >
      <div class="report-title">
        <v-form ref="form">
          <v-text-field
            :color="color"
            placeholder="Titel"
            v-model="title"
            :rules="titleRules"
          />
        </v-form>
      </div>
    </ModalConfirm>
    <div class="hidden">
      <div ref="graph" class="pdf-content">
        <PdfHeader />
        <v-row class="row-padding">
          <span class="base-grey-bold text-h5 padding"
            >Entwicklung der
            {{ products[$router.currentRoute.params.producttype] }} im Zeitraum
            {{ zeitraum() }}</span
          >
        </v-row>
        <v-row class="row-padding">
          <span class="inder base-bold-color padding">Bedingungen</span>
          <span
            v-if="
              $router.currentRoute.params.producttype === 'wettbewerb-baugeld'
            "
            class="inder padding"
            >Beleihung:
            <v-chip small class="selected-tag inter">{{
              currentBeleihung
            }}</v-chip></span
          >
          <span
            v-if="
              $router.currentRoute.params.producttype === 'wettbewerb-baugeld'
            "
            class="padding inter"
            >Sollzinsbindung:
            <v-chip small class="selected-tag inter">{{
              currentSollzinsbindung
            }}</v-chip></span
          >
          <span v-if="validate" class="padding inter"
            >Anlagebetrag:
            <v-chip small class="selected-tag inter padding">{{
              currentAnlagebetrag
            }}</v-chip></span
          >
          <span v-if="validate" class="padding inter"
            ><span>Angebote für</span>:
            <v-chip small class="selected-tag inter padding">{{
              currentKundenkreis
            }}</v-chip></span
          >
          <span
            v-if="
              $router.currentRoute.params.producttype === 'wettbewerb-festgeld'
            "
            class="padding inter"
            >Anlagedauer:
            <v-chip small class="selected-tag inter padding">{{
              currentAnlagedauer
            }}</v-chip></span
          >
          <span v-if="validate" class="inter"
            >Vermittlerangebote:
            <v-chip small class="selected-tag inter">{{
              currentVermittlerangebote
            }}</v-chip></span
          >
        </v-row>
        <ReportChart
          style="min-height: 550px; min-width: 1200px"
          :isShow="isShow"
          :param="singleReport.chartParam"
          :form="singleReport.formParam"
          :data="copyReport.data"
          :minWidth="options[$router.currentRoute.params.producttype].minWidth"
          :maxWidth="options[$router.currentRoute.params.producttype].maxWidth"
          :minColor="options[$router.currentRoute.params.producttype].minColor"
          :maxColor="options[$router.currentRoute.params.producttype].maxColor"
          :period="period"
        />
        <span class="inter"
          >Der Mittelwert basiert auf Konditionen von über
          {{ providerCount }} Anbietern.
        </span>
      </div>
    </div>
    <v-container>
      <div>
        <ChartHeader
          module="wettbewerb"
          nameSpace="singleReport.chartParam"
          :data="singleReport"
          :formParam="copyReport.formParam"
        />
        <ReportChart
          :isShow="isShow"
          :param="singleReport.chartParam"
          :form="singleReport.formParam"
          :data="copyReport.data"
          :minWidth="options[$router.currentRoute.params.producttype].minWidth"
          :maxWidth="options[$router.currentRoute.params.producttype].maxWidth"
          :minColor="options[$router.currentRoute.params.producttype].minColor"
          :maxColor="options[$router.currentRoute.params.producttype].maxColor"
          :period="period"
        />
      </div>
      <div class="table-container" v-if="!loading">
        <v-row justify="space-between">
          <v-col v-if="validatePermissions">
            <v-btn
              :color="color"
              v-if="isAvailable"
              @click="handleChangeShowTable"
              >{{ getTextButton }}</v-btn
            >
            <v-btn
              v-if="isShowTable"
              class="ml-1"
              :color="color"
              @click="exportFile"
              >Download XLS</v-btn
            >
            <v-btn v-if="isShowTable" class="ml-1 csv-btn" :color="color">
              <vue-json-to-csv
                separator=";"
                :json-data="rowsCSV"
                :labels="headersCSV"
                :csv-title="csvFileName"
              >
                Download CSV
              </vue-json-to-csv>
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn
              :disabled="loading || isGenerateIframe"
              :loading="loading || isGenerateIframe"
              v-if="!!copyReport.data.length"
              class="mr-1 btn"
              :color="color"
              @click="generateIframe"
            >
              Grafik einbinden
              <v-icon small style="color: #fff">mdi-share</v-icon></v-btn
            >
            <v-btn :color="color" @click="onOpen">Speichern</v-btn>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col xs="12" sm="12" md="8" lg="8" xl="8">
            <Alert
              style="text-align: center"
              v-if="isShowHint"
              type="info"
              color="orange"
              message="In der Vollversion können Sie die kompletten Datenreihen einsehen und exportieren."
            />
          </v-col>
        </v-row>
        <Table v-if="isShowTable" :headers="headers" :rows="rows" ref="table" />
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import VueJsonToCsv from 'vue-json-to-csv';

import {
  checkedCheckBoxes,
  createPDF,
  fileName,
  headersForCSV,
  mappedAnlagebetrag,
  mappedAnlagedauer,
  mappedKunderkreis,
  mappedZeitraum,
  payloadForUpdateReport,
  rowsForCSV,
  wettbewerbTableHeaders,
} from '../../../../utils/helpers';

import ReportChart from './ReportChart';
import Loader from '../../../common/Loader/Loader';
import Table from '../../../common/Table/Table';
import ChartHeader from '../../../common/Chart/ChartHeader/ChartHeader';
import ReportForm from './ReportForm';
import ModalConfirm from '../../../common/ModalConfirm/ModalConfirm';
import Alert from '../../../common/Alert/Alert';
import PdfHeader from '../../../../components/common/PdfHeader/PdfHeader';
import CopyIframeDialog from '../../../common/CopyIframeDialog/CopyIframeDialog';
import SnackBar from '../../../../../../src/components/fmh_components/common/SnackBar/SnackBar';

import {
  BELEIHUNG_TAGS_OPTIONS,
  PRODUCT_TYPES,
  SOLLZINSBINDUNG_TAGS_OPTIONS,
  URLS,
  WETTBEWERB_PRODUCT_TYPES,
} from '../../../../utils/constants';
import { BASE_BLACK_COLOR, HOST } from '../../../../../../src/utils/constants';
import { exportXLS } from '../../../../../../src/utils/helpers';
import i18n from '../../../../../../src/plugins/i18n';

export default {
  name: 'Report',
  components: {
    VueJsonToCsv,
    PdfHeader,
    ReportChart,
    Loader,
    Table,
    ChartHeader,
    ReportForm,
    ModalConfirm,
    Alert,
    CopyIframeDialog,
    SnackBar,
  },
  computed: {
    ...mapGetters({
      loading: 'wettbewerb/loading',
      singleReport: 'wettbewerb/singleReport',
      isAuthorized: 'auth/isAuthorized',
      allReportsTagesgeld: 'wettbewerb/allReportsTagesgeld',
      allReportsFestgeld: 'wettbewerb/allReportsFestgeld',
      allReportsBaugeld: 'wettbewerb/allReportsBaugeld',
      permissions: 'auth/getPermissions',
      copyReport: 'wettbewerb/getCopyReport',
      user: 'auth/getUser',
      snackbarData: 'reports/getSnackbarData',
    }),
    csvFileName() {
      const name = fileName(this.singleReport.title);
      const date = `${this.$date(this.singleReport.timestamp).format(
        'DD.MM.YYYY'
      )}`;
      return `${name}_${date}`;
    },
    headersCSV() {
      return headersForCSV(this.headers);
    },
    rowsCSV() {
      return rowsForCSV(this.rows, this.headersCSV, this.$date);
    },
    period() {
      return this.parsePeriod(this.singleReport?.formParam, this.$date);
    },
    validatePermissions() {
      return this.permissions.some((permission) =>
        [
          'mortgage.competitionData',
          'timeDeposits.competitionData',
          'callMoney.competitionData',
        ].includes(permission)
      );
    },
    isAvailable() {
      return this.permissions.includes(
        WETTBEWERB_PRODUCT_TYPES[this.$router.currentRoute.params.producttype]
      );
    },
    rows() {
      return this.singleReport?.data?.length
        ? [...this.singleReport.data].reverse()
        : [];
    },
    validate() {
      return (
        this.$router.currentRoute.params.producttype ===
          'wettbewerb-tagesgeld' ||
        this.$router.currentRoute.params.producttype === 'wettbewerb-festgeld'
      );
    },
    currentBeleihung() {
      const search = BELEIHUNG_TAGS_OPTIONS.find(
        (i) => i.value === this.singleReport?.formParam?.beleihung
      );
      return search?.text || '';
    },
    currentSollzinsbindung() {
      const search = SOLLZINSBINDUNG_TAGS_OPTIONS.find(
        (i) => i.value === this.singleReport?.formParam?.sollzinsbindung
      );
      return search?.text || '';
    },
    currentVermittlerangebote() {
      return this.singleReport?.formParam?.vermittlerangebote?.selected ===
        false
        ? 'Nein'
        : 'Ja';
    },
    currentAnlagedauer() {
      return mappedAnlagedauer(this.singleReport.formParam.anlagedauer);
    },
    currentKundenkreis() {
      return mappedKunderkreis(this.singleReport.formParam.kundenkreis);
    },
    currentAnlagebetrag() {
      return mappedAnlagebetrag(this.singleReport.formParam.anlagebetrag, 0);
    },
    locale() {
      return this.language === 'de' ? 'de-DE' : 'en-US';
    },
    isShowHint() {
      return this.user?.isDemoUser && this.isShowTable;
    },
    getTextButton() {
      return this.isShowTable ? 'Datenreihe ausblenden' : 'Datenreihe anzeigen';
    },
    isShow() {
      let data = this.singleReport;
      if (typeof this.singleReport.formParam.bankname1 === 'object') {
        const result = this.filter('zins1');
        Object.assign(data, { data: result });
      }
      if (typeof this.singleReport.formParam.bankname2 === 'object') {
        const result = this.filter('zins2');
        Object.assign(data, { data: result });
      }
      if (typeof this.singleReport.formParam.bankname3 === 'object') {
        const result = this.filter('zins3');
        Object.assign(data, { data: result });
      }
      if (typeof this.singleReport.formParam.bankname4 === 'object') {
        const result = this.filter('zins4');
        Object.assign(data, { data: result });
      }
      if (typeof this.singleReport.formParam.bankname5 === 'object') {
        const result = this.filter('zins5');
        Object.assign(data, { data: result });
      }
      return checkedCheckBoxes(data);
    },
  },
  data() {
    return {
      isGenerateIframe: false,
      iframe: '',
      iframeDialog: false,
      options: {
        'wettbewerb-baugeld': {
          minWidth: 2.5,
          maxWidth: 1.5,
          minColor: '#EBC100',
          maxColor: '#181818',
        },
        'wettbewerb-festgeld': {
          minWidth: 1.5,
          maxWidth: 2.5,
          minColor: '#cc0086',
          maxColor: '#ff3339',
        },
        'wettbewerb-tagesgeld': {
          minWidth: 1.5,
          maxWidth: 2.5,
          minColor: '#cc0086',
          maxColor: '#ff3339',
        },
      },
      textActionButtons: {
        confirm: 'Speichern',
        reject: 'Abbrechen',
      },
      titleRules: [],
      title: '',
      dialog: false,
      color: BASE_BLACK_COLOR,
      headers: [],
      isShowTable: false,
      products: {
        'wettbewerb-tagesgeld': 'Tagesgeldzinsen',
        'wettbewerb-festgeld': 'Festgeldzinsen',
        'wettbewerb-baugeld': 'Hypothekenzinsen',
      },
      types: {
        'wettbewerb-tagesgeld': 'anbieter/loadAnbieterAsync',
        'wettbewerb-festgeld': 'anbieter/loadAnbieterFestGeld',
        'wettbewerb-baugeld': 'anbieter/loadAnbieterHypotheken',
      },
      language: i18n.locale,
    };
  },
  methods: {
    ...mapActions({
      fetchSingleReport: 'wettbewerb/fetchSingleReport',
      updateReport: 'wettbewerb/updateReport',
      saveChartData: 'interestCharts/saveChartData',
    }),
    ...mapMutations({
      setSingleReport: 'wettbewerb/setSingleReport',
      setLoading: 'wettbewerb/loading',
      setSnackBarData: 'reports/setSnackBarData',
    }),
    handleChangeIframeDialog(val) {
      this.iframeDialog = val;
    },
    async generateIframe() {
      try {
        this.isGenerateIframe = true;
        const data = await this.saveChartData({
          data: this.copyReport.data,
        });
        if (data?.data?.slug) {
          const str = JSON.stringify({
            params: {
              page: this.$router.currentRoute.params.producttype,
              chartParam: this.singleReport.chartParam,
              formParam: this.copyReport.formParam,
            },
          });
          const objJsonB64 = btoa(str);
          const src =
            process.env.NODE_ENV !== 'production'
              ? `http://localhost:8080/rate-chart/${data.data.slug}/?params=${objJsonB64}`
              : `${HOST}/rate-chart/${data.data.slug}/?params=${objJsonB64}`;
          this.iframe = `<iframe \n  src="${src}" \n width="100%" \n height="900px" \n>
</iframe>`;
          this.iframeDialog = true;
          this.isGenerateIframe = false;
        } else {
          this.setSnackBarData({
            text: 'Bei der API Anfrage trat ein Fehler auf',
            show: true,
            timeout: 3000,
          });
        }
        this.isGenerateIframe = false;
      } catch (e) {
        this.setSnackBarData({
          text: 'Bei der API Anfrage trat ein Fehler auf',
          show: true,
          timeout: 3000,
        });
        this.isGenerateIframe = false;
      }
    },
    zeitraum() {
      return mappedZeitraum(this.singleReport?.formParam, this.locale);
    },
    handleChangeShowTable() {
      this.isShowTable = !this.isShowTable;
    },
    exportFile() {
      const name = fileName();
      const date = `Es werden die Daten vom ${this.$date(
        this.singleReport.timestamp
      ).format('DD.MM.YYYY')} angezeigt`;
      exportXLS(this.$refs?.table?.$el, name, date);
    },
    onDialogChanged(val) {
      this.dialog = val;
    },
    onOpen() {
      this.title = this.singleReport.title;
      this.dialog = true;
    },
    filter(key) {
      return this.singleReport?.data?.map((item) => {
        return { ...item, ...{ [key]: null } };
      });
    },
    update() {
      this.titleRules = [(v) => !!v || 'Titel ist erforderlich'];
      const host = `${
        URLS[PRODUCT_TYPES[this.$router.currentRoute.params.producttype]]
      }rechner/webapi/banken-index/index`;
      const reportType =
        PRODUCT_TYPES[this.$router.currentRoute.params.producttype];
      setTimeout(async () => {
        if (this.$refs.form.validate()) {
          const result = payloadForUpdateReport(
            this.singleReport.formParam,
            this.singleReport.slug,
            reportType,
            host
          );
          const payload = { ...result, title: this.title };
          await this.updateReport(payload);
          this.title = '';
          return this.$router.push(
            `/${this.$router.currentRoute.params.producttype}`
          );
        }
      });
    },
    async loadPdf() {
      if (this.copyReport?.data?.length) {
        this.setLoading(true);
        const names = {
          'wettbewerb-tagesgeld': 'wettbewerb-tagesgeld.pdf',
          'wettbewerb-festgeld': 'wettbewerb-festgeld.pdf',
          'wettbewerb-baugeld': 'wettbewerb-baugeld.pdf',
        };
        await createPDF(
          this.$refs.graph,
          names[this.$router.currentRoute.params.producttype],
          this.singleReport.formParam,
          this.$date
        );
        this.setLoading(false);
      }
    },
  },
  watch: {
    singleReport() {
      this.headers = wettbewerbTableHeaders(
        this.singleReport.formParam.bankname1,
        this.singleReport.formParam.bankname2,
        this.singleReport.formParam.bankname3,
        this.singleReport.formParam.bankname4,
        this.singleReport.formParam.bankname5
      );
    },
    isAuthorized(val) {
      if (val) {
        if (
          this.allReportsTagesgeld.length === 0 &&
          this.allReportsFestgeld.length === 0 &&
          this.allReportsBaugeld.length === 0
        ) {
          this.fetchSingleReport(this.$router.currentRoute.params.slug);
        }
      }
    },
  },
  created() {
    if (this.isAuthorized) {
      if (
        this.$router.currentRoute.params.producttype ===
          'wettbewerb-tagesgeld' &&
        this.allReportsTagesgeld.length !== 0
      ) {
        const searchReport = this.allReportsTagesgeld.find(
          (item) => item.slug === this.$router.currentRoute.params.slug
        );
        this.setSingleReport(searchReport);
      }
      if (
        this.$router.currentRoute.params.producttype ===
          'wettbewerb-festgeld' &&
        this.allReportsFestgeld.length !== 0
      ) {
        const searchReport = this.allReportsFestgeld.find(
          (item) => item.slug === this.$router.currentRoute.params.slug
        );
        this.setSingleReport(searchReport);
      }
      if (
        this.$router.currentRoute.params.producttype === 'wettbewerb-baugeld' &&
        this.allReportsBaugeld.length !== 0
      ) {
        const searchReport = this.allReportsBaugeld.find(
          (item) => item.slug === this.$router.currentRoute.params.slug
        );
        this.setSingleReport(searchReport);
      }
      if (
        this.$router.currentRoute.params.producttype === 'wettbewerb-baugeld' &&
        this.allReportsBaugeld.length === 0
      ) {
        this.fetchSingleReport(this.$router.currentRoute.params.slug);
      }
      if (
        this.$router.currentRoute.params.producttype ===
          'wettbewerb-tagesgeld' &&
        this.allReportsTagesgeld.length === 0
      ) {
        this.fetchSingleReport(this.$router.currentRoute.params.slug);
      }
      if (
        this.$router.currentRoute.params.producttype ===
          'wettbewerb-festgeld' &&
        this.allReportsFestgeld.length === 0
      ) {
        this.fetchSingleReport(this.$router.currentRoute.params.slug);
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.form {
  background-color: $main-background;
  margin-top: 32px;
}

.hidden {
  width: 100%;
  display: flex;
  justify-content: center;
  opacity: 0;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.pdf-content {
  width: 100%;
  padding: 30px 60px 30px 60px;
}
.padding {
  padding-right: 15px;
}
.row-padding {
  padding-bottom: 20px;
}
.report-wrapper {
  position: relative;
  overflow-x: hidden;
}
[class~='table-container'] {
  & > [class~='col'] {
    & > [class~='csv-btn'] {
      & > [class~='btn__content'] {
        height: 100% !important;
      }
    }
  }
}
</style>
